export const getCountryFlag = ($code) => {
    $code = $code?.toUpperCase();
    if($code == 'AD') return '🇦🇩';
    if($code == 'AE') return '🇦🇪';
    if($code == 'AF') return '🇦🇫';
    if($code == 'AG') return '🇦🇬';
    if($code == 'AI') return '🇦🇮';
    if($code == 'AL') return '🇱🇷';
    if($code == 'AM') return '🇦🇲';
    if($code == 'AO') return '🇦🇴';
    if($code == 'AQ') return '🇦🇶';
    if($code == 'AR') return '🇱🇷';
    if($code == 'AS') return '🇱🇷';
    if($code == 'AT') return '🇦🇹';
    if($code == 'AU') return '🇦🇺';
    if($code == 'AW') return '🇦🇼';
    if($code == 'AX') return '🇦🇽';
    if($code == 'AZ') return '🇱🇷';
    if($code == 'BA') return '🇧🇦';
    if($code == 'BB') return '🇧🇧';
    if($code == 'BD') return '🇧🇩';
    if($code == 'BE') return '🇧🇪';
    if($code == 'BF') return '🇧🇫';
    if($code == 'BG') return '🇧🇬';
    if($code == 'BH') return '🇧🇭';
    if($code == 'BI') return '🇧🇮';
    if($code == 'BJ') return '🇧🇯';
    if($code == 'BL') return '🇧🇱';
    if($code == 'BM') return '🇧🇲';
    if($code == 'BN') return '🇧🇳';
    if($code == 'BO') return '🇧🇴';
    if($code == 'BQ') return '🇧🇶';
    if($code == 'BR') return '🇧🇷';
    if($code == 'BS') return '🇧🇸';
    if($code == 'BT') return '🇧🇹';
    if($code == 'BV') return '🇧🇻';
    if($code == 'BW') return '🇧🇼';
    if($code == 'BY') return '🇧🇾';
    if($code == 'BZ') return '🇧🇿';
    if($code == 'CA') return '🇱🇷';
    if($code == 'CC') return '🇨🇨';
    if($code == 'CD') return '🇨🇩';
    if($code == 'CF') return '🇨🇫';
    if($code == 'CG') return '🇨🇬';
    if($code == 'CH') return '🇨🇭';
    if($code == 'CI') return '🇨🇮';
    if($code == 'CK') return '🇨🇰';
    if($code == 'CL') return '🇨🇱';
    if($code == 'CM') return '🇨🇲';
    if($code == 'CN') return '🇨🇳';
    if($code == 'CO') return '🇱🇷';
    if($code == 'CR') return '🇨🇷';
    if($code == 'CU') return '🇨🇺';
    if($code == 'CV') return '🇨🇻';
    if($code == 'CW') return '🇨🇼';
    if($code == 'CX') return '🇨🇽';
    if($code == 'CY') return '🇨🇾';
    if($code == 'CZ') return '🇨🇿';
    if($code == 'DE') return '🇱🇷';
    if($code == 'DJ') return '🇩🇯';
    if($code == 'DK') return '🇩🇰';
    if($code == 'DM') return '🇩🇲';
    if($code == 'DO') return '🇩🇴';
    if($code == 'DZ') return '🇩🇿';
    if($code == 'EC') return '🇪🇨';
    if($code == 'EE') return '🇪🇪';
    if($code == 'EG') return '🇪🇬';
    if($code == 'EH') return '🇪🇭';
    if($code == 'ER') return '🇪🇷';
    if($code == 'ES') return '🇪🇸';
    if($code == 'ET') return '🇪🇹';
    if($code == 'FI') return '🇫🇮';
    if($code == 'FJ') return '🇫🇯';
    if($code == 'FK') return '🇫🇰';
    if($code == 'FM') return '🇫🇲';
    if($code == 'FO') return '🇫🇴';
    if($code == 'FR') return '🇫🇷';
    if($code == 'GA') return '🇬🇦';
    if($code == 'GB') return '🇬🇧';
    if($code == 'GD') return '🇬🇩';
    if($code == 'GE') return '🇬🇪';
    if($code == 'GF') return '🇬🇫';
    if($code == 'GG') return '🇬🇬';
    if($code == 'GH') return '🇬🇭';
    if($code == 'GI') return '🇬🇮';
    if($code == 'GL') return '🇬🇱';
    if($code == 'GM') return '🇬🇲';
    if($code == 'GN') return '🇬🇳';
    if($code == 'GP') return '🇬🇵';
    if($code == 'GQ') return '🇬🇶';
    if($code == 'GR') return '🇬🇷';
    if($code == 'GS') return '🇬🇸';
    if($code == 'GT') return '🇬🇹';
    if($code == 'GU') return '🇬🇺';
    if($code == 'GW') return '🇬🇼';
    if($code == 'GY') return '🇬🇾';
    if($code == 'HK') return '🇭🇰';
    if($code == 'HM') return '🇭🇲';
    if($code == 'HN') return '🇭🇳';
    if($code == 'HR') return '🇭🇷';
    if($code == 'HT') return '🇭🇹';
    if($code == 'HU') return '🇭🇺';
    if($code == 'IE') return '🇮🇪';
    if($code == 'IM') return '🇮🇲';
    if($code == 'IO') return '🇮🇴';
    if($code == 'IQ') return '🇮🇶';
    if($code == 'IR') return '🇮🇷';
    if($code == 'IS') return '🇮🇸';
    if($code == 'IT') return '🇮🇹';
    if($code == 'JE') return '🇯🇪';
    if($code == 'JM') return '🇯🇲';
    if($code == 'JO') return '🇯🇴';
    if($code == 'JP') return '🇯🇵';
    if($code == 'KE') return '🇰🇪';
    if($code == 'KG') return '🇰🇬';
    if($code == 'KH') return '🇰🇭';
    if($code == 'KI') return '🇰🇮';
    if($code == 'KM') return '🇰🇲';
    if($code == 'KN') return '🇰🇳';
    if($code == 'KP') return '🇰🇵';
    if($code == 'KR') return '🇰🇷';
    if($code == 'KW') return '🇰🇼';
    if($code == 'KY') return '🇰🇾';
    if($code == 'KZ') return '🇰🇿';
    if($code == 'LA') return '🇱🇷';
    if($code == 'LB') return '🇱🇧';
    if($code == 'LC') return '🇱🇨';
    if($code == 'LI') return '🇱🇮';
    if($code == 'LK') return '🇱🇰';
    if($code == 'LR') return '🇱🇷';
    if($code == 'LS') return '🇱🇸';
    if($code == 'LT') return '🇱🇹';
    if($code == 'LU') return '🇱🇺';
    if($code == 'LV') return '🇱🇻';
    if($code == 'LY') return '🇱🇾';
    if($code == 'MA') return '🇱🇷';
    if($code == 'MC') return '🇲🇨';
    if($code == 'MD') return '🇱🇷';
    if($code == 'ME') return '🇱🇷';
    if($code == 'MF') return '🇲🇫';
    if($code == 'MG') return '🇲🇬';
    if($code == 'MH') return '🇲🇭';
    if($code == 'MK') return '🇲🇰';
    if($code == 'ML') return '🇲🇱';
    if($code == 'MM') return '🇲🇲';
    if($code == 'MP') return '🇱🇷';
    if($code == 'MQ') return '🇲🇶';
    if($code == 'MR') return '🇲🇷';
    if($code == 'MU') return '🇲🇺';
    if($code == 'MV') return '🇲🇻';
    if($code == 'MW') return '🇲🇼';
    if($code == 'MX') return '🇲🇽';
    if($code == 'MY') return '🇲🇾';
    if($code == 'MZ') return '🇲🇿';
    if($code == 'NA') return '🇳🇦';
    if($code == 'NE') return '🇱🇷';
    if($code == 'NF') return '🇳🇫';
    if($code == 'NG') return '🇳🇬';
    if($code == 'NI') return '🇳🇮';
    if($code == 'NL') return '🇳🇱';
    if($code == 'NO') return '🇳🇴';
    if($code == 'NP') return '🇳🇵';
    if($code == 'NR') return '🇳🇷';
    if($code == 'NU') return '🇳🇺';
    if($code == 'NZ') return '🇳🇿';
    if($code == 'OM') return '🇴🇲';
    if($code == 'PE') return '🇵🇪';
    if($code == 'PF') return '🇵🇫';
    if($code == 'PG') return '🇵🇬';
    if($code == 'PH') return '🇵🇭';
    if($code == 'PK') return '🇵🇰';
    if($code == 'PL') return '🇵🇱';
    if($code == 'PM') return '🇵🇲';
    if($code == 'PN') return '🇵🇳';
    if($code == 'PS') return '🇵🇸';
    if($code == 'PT') return '🇵🇹';
    if($code == 'PW') return '🇵🇼';
    if($code == 'PY') return '🇵🇾';
    if($code == 'QA') return '🇶🇦';
    if($code == 'RE') return '🇷🇪';
    if($code == 'RO') return '🇷🇴';
    if($code == 'RS') return '🇷🇸';
    if($code == 'RU') return '🇷🇺';
    if($code == 'RW') return '🇷🇼';
    if($code == 'SA') return '🇸🇦';
    if($code == 'SB') return '🇸🇧';
    if($code == 'SE') return '🇸🇪';
    if($code == 'SG') return '🇸🇬';
    if($code == 'SH') return '🇸🇭';
    if($code == 'SI') return '🇸🇮';
    if($code == 'SJ') return '🇸🇯';
    if($code == 'SK') return '🇸🇰';
    if($code == 'SL') return '🇸🇱';
    if($code == 'SM') return '🇸🇲';
    if($code == 'SN') return '🇸🇳';
    if($code == 'SO') return '🇸🇴';
    if($code == 'SR') return '🇸🇷';
    if($code == 'SS') return '🇸🇸';
    if($code == 'ST') return '🇸🇹';
    if($code == 'SV') return '🇸🇻';
    if($code == 'SX') return '🇸🇽';
    if($code == 'SY') return '🇸🇾';
    if($code == 'SZ') return '🇸🇿';
    if($code == 'TC') return '🇹🇨';
    if($code == 'TD') return '🇹🇩';
    if($code == 'TF') return '🇹🇫';
    if($code == 'TG') return '🇹🇬';
    if($code == 'TH') return '🇹🇭';
    if($code == 'TJ') return '🇹🇯';
    if($code == 'TK') return '🇹🇰';
    if($code == 'TL') return '🇹🇱';
    if($code == 'TM') return '🇹🇲';
    if($code == 'TN') return '🇱🇷';
    if($code == 'TO') return '🇹🇴';
    if($code == 'TR') return '🇹🇷';
    if($code == 'TT') return '🇹🇹';
    if($code == 'TV') return '🇹🇻';
    if($code == 'TW') return '🇹🇼';
    if($code == 'TZ') return '🇹🇿';
    if($code == 'UA') return '🇺🇦';
    if($code == 'UG') return '🇺🇬';
    if($code == 'UM') return '🇺🇲';
    if($code == 'US') return '🇺🇸';
    if($code == 'UY') return '🇺🇾';
    if($code == 'UZ') return '🇺🇿';
    if($code == 'VA') return '🇱🇷';
    if($code == 'VC') return '🇻🇨';
    if($code == 'VE') return '🇻🇪';
    if($code == 'VG') return '🇻🇬';
    if($code == 'VI') return '🇱🇷';
    if($code == 'VN') return '🇻🇳';
    if($code == 'VU') return '🇻🇺';
    if($code == 'WF') return '🇼🇫';
    if($code == 'WS') return '🇼🇸';
    if($code == 'XK') return '🇽🇰';
    if($code == 'YE') return '🇾🇪';
    if($code == 'YT') return '🇾🇹';
    if($code == 'ZA') return '🇿🇦';
    if($code == 'ZM') return '🇿🇲';
    if($code == 'AFG') return '🇦🇫';
    if($code == 'ALB') return '🇦🇱';
    if($code == 'AK') return '🇺🇸'
    if($code == 'DZA') return '🇩🇿'
    if($code == 'AND') return '🇦🇩'
    if($code == 'AB') return '🇨🇦'
    if($code == 'AGO') return '🇦🇴'
    if($code == 'ATG') return '🇦🇬'
    if($code == 'ARG') return '🇦🇷'
    if($code == 'ARM') return '🇦🇲'
    if($code == 'ABW') return '🇦🇼'
    if($code == 'AUS') return '🇦🇺'
    if($code == 'AZE') return '🇦🇿'
    if($code == 'BHS') return '🇧🇸'
    if($code == 'BHR') return '🇧🇭'
    if($code == 'BGD') return '🇧🇩'
    if($code == 'BRB') return '🇧🇧'
    if($code == 'BLR') return '🇧🇾'
    if($code == 'BEL') return '🇧🇪'
    if($code == 'BLZ') return '🇧🇿'
    if($code == 'BEN') return '🇧🇯'
    if($code == 'BMU') return '🇧🇲'
    if($code == 'BTN') return '🇧🇹'
    if($code == 'BOL') return '🇧🇴'
    if($code == 'BIH') return '🇧🇦'
    if($code == 'BWA') return '🇧🇼'
    if($code == 'BRA') return '🇧🇷'
    if($code == 'BC') return '🇻🇬'
    if($code == 'BRN') return '🇧🇳'
    if($code == 'BGR') return '🇧🇬'
    if($code == 'BFA') return '🇧🇫'
    if($code == 'BDI') return '🇧🇮'
    if($code == 'KHM') return '🇰🇭'
    if($code == 'CMR') return '🇨🇲'
    if($code == 'CAN') return '🇨🇦'
    if($code == 'CPV') return '🇨🇻'
    if($code == 'BES') return '🇧🇶'
    if($code == 'CYM') return '🇰🇾'
    if($code == 'TCD') return '🇹🇩'
    if($code == 'CHL') return '🇨🇱'
    if($code == 'CHN') return '🇨🇳'
    if($code == 'COL') return '🇨🇴'
    if($code == 'COM') return '🇰🇲'
    if($code == 'COG') return '🇨🇬'
    if($code == 'CT') return '🇺🇸'
    if($code == 'COK') return '🇨🇰'
    if($code == 'CRI') return '🇨🇷'
    if($code == 'CIV') return '🇨🇮'
    if($code == 'HRV') return '🇭🇷'
    if($code == 'CUB') return '🇨🇺'
    if($code == 'CUW') return '🇨🇼'
    if($code == 'CYP') return '🇨🇾'
    if($code == 'CZE') return '🇨🇿'
    if($code == 'PRK') return '🇰🇵'
    if($code == 'COD') return '🇨🇩'
    if($code == 'DNK') return '🇩🇰'
    if($code == 'DC') return '🇺🇸'
    if($code == 'DJI') return '🇩🇯'
    if($code == 'DMA') return '🇩🇲'
    if($code == 'DOM') return '🇩🇴'
    if($code == 'ECU') return '🇪🇨'
    if($code == 'EGY') return '🇪🇬'
    if($code == 'SLV') return '🇸🇻'
    if($code == 'GNQ') return '🇬🇶'
    if($code == 'ERI') return '🇪🇷'
    if($code == 'EST') return '🇪🇪'
    if($code == 'ETH') return '🇪🇹'
    if($code == 'EU') return '🇪🇺'
    if($code == 'FJI') return '🇫🇯'
    if($code == 'FIN') return '🇫🇮'
    if($code == 'FL') return '🇺🇸'
    if($code == 'FRA') return '🇫🇷'
    if($code == 'PYF') return '🇵🇫'
    if($code == 'GAB') return '🇬🇦'
    if($code == 'GMB') return '🇬🇲'
    if($code == 'GEO') return '🇱🇷'
    if($code == 'DEU') return '🇩🇪'
    if($code == 'GHA') return '🇬🇭'
    if($code == 'GRC') return '🇬🇷'
    if($code == 'GRD') return '🇬🇩'
    if($code == 'GTM') return '🇬🇹'
    if($code == 'GGY') return '🇬🇬'
    if($code == 'GIN') return '🇬🇳'
    if($code == 'GNB') return '🇬🇼'
    if($code == 'GUY') return '🇬🇾'
    if($code == 'HTI') return '🇭🇹'
    if($code == 'HI') return '🇺🇸'
    if($code == 'VAT') return '🇻🇦'
    if($code == 'HND') return '🇭🇳'
    if($code == 'HKG') return '🇭🇰'
    if($code == 'HUN') return '🇭🇺'
    if($code == 'ISL') return '🇮🇸'
    if($code == 'ID') return '🇺🇸'
    if($code == 'IL') return '🇺🇸'
    if($code == 'IND') return '🇮🇳'
    if($code == 'IN') return '🇺🇸'
    if($code == 'IDN') return '🇮🇩'
    if($code == 'XPO') return '🏳'
    if($code == 'IA') return '🇺🇸'
    if($code == 'IRN') return '🇮🇷'
    if($code == 'IRQ') return '🇮🇶'
    if($code == 'IRL') return '🇮🇪'
    if($code == 'IMN') return '🇮🇲'
    if($code == 'ISR') return '🇮🇱'
    if($code == 'ITA') return '🇮🇹'
    if($code == 'JPN') return '🇯🇵'
    if($code == 'JAM') return '🇯🇲'
    if($code == 'JEY') return '🇯🇪'
    if($code == 'JOR') return '🇯🇴'
    if($code == 'KS') return '🇺🇸'
    if($code == 'KAZ') return '🇰🇿'
    if($code == 'RKS') return '🇽🇰'
    if($code == 'KE') return '🇰🇪'
    if($code == 'KWT') return '🇰🇼'
    if($code == 'KGZ') return '🇰🇬'
    if($code == 'LVA') return '🇱🇻'
    if($code == 'LBN') return '🇱🇧'
    if($code == 'LSO') return '🇱🇸'
    if($code == 'LBR') return '🇱🇷'
    if($code == 'LBY') return '🇱🇾'
    if($code == 'LIE') return '🇱🇮'
    if($code == 'LTU') return '🇱🇹'
    if($code == 'LUX') return '🇱🇺'
    if($code == 'MAC') return '🇲🇴'
    if($code == 'MDG') return '🇲🇬'
    if($code == 'MWI') return '🇲🇼'
    if($code == 'MYS') return '🇲🇾'
    if($code == 'MDV') return '🇲🇻'
    if($code == 'MLI') return '🇲🇱'
    if($code == 'MLT') return '🇲🇹'
    if($code == 'MB') return '🇨🇦'
    if($code == 'MHL') return '🇲🇭'
    if($code == 'MUS') return '🇲🇺'
    if($code == 'MEX') return '🇲🇽'
    if($code == 'MI') return '🇺🇸'
    if($code == 'FSM') return '🇫🇲'
    if($code == 'MN') return '🇺🇸'
    if($code == 'MS') return '🇺🇸'
    if($code == 'MO') return '🇺🇸'
    if($code == 'MCO') return '🇲🇨'
    if($code == 'MNG') return '🇲🇳'
    if($code == 'MT') return '🇺🇸'
    if($code == 'MNE') return '🇲🇪'
    if($code == 'MSR') return '🇲🇸'
    if($code == 'MAR') return '🇲🇦'
    if($code == 'MOZ') return '🇲🇿'
    if($code == 'MMR') return '🇲🇲'
    if($code == 'NAM') return '🇳🇦'
    if($code == 'NPL') return '🇳🇵'
    if($code == 'NLD') return '🇳🇱'
    if($code == 'ANT') return '🇸🇽'
    if($code == 'NV') return '🇺🇸'
    if($code == 'NB') return '🇨🇦'
    if($code == 'NH') return '🇺🇸'
    if($code == 'NJ') return '🇺🇸'
    if($code == 'NM') return '🇱🇷'
    if($code == 'NY') return '🇺🇸'
    if($code == 'NZL') return '🇳🇿'
    if($code == 'NL') return '🇨🇦'
    if($code == 'NIC') return '🇳🇮'
    if($code == 'NER') return '🇳🇪'
    if($code == 'NGA') return '🇳🇬'
    if($code == 'NC') return '🇺🇸'
    if($code == 'ND') return '🇺🇸'
    if($code == 'NOR') return '🇳🇴'
    if($code == 'NS') return '🇨🇦'
    if($code == 'NU') return '🇨🇦'
    if($code == 'OH') return '🇺🇸'
    if($code == 'OK') return '🇺🇸'
    if($code == 'OMN') return '🇴🇲'
    if($code == 'ON') return '🇨🇦'
    if($code == 'OR') return '🇺🇸'
    if($code == 'PAK') return '🇵🇰'
    if($code == 'PLW') return '🇵🇼'
    if($code == 'PSE') return '🇵🇸'
    if($code == 'PAN') return '🇵🇦'
    if($code == 'PNG') return '🇵🇬'
    if($code == 'PRY') return '🇵🇾'
    if($code == 'PA') return '🇺🇸'
    if($code == 'PER') return '🇵🇪'
    if($code == 'PHL') return '🇵🇭'
    if($code == 'POL') return '🇵🇱'
    if($code == 'PRT') return '🇵🇹'
    if($code == 'PE') return '🇵🇹'
    if($code == 'PR') return '🇱🇷'
    if($code == 'QAT') return '🇶🇦'
    if($code == 'QC') return '🇨🇦'
    if($code == 'KOR') return '🇰🇷'
    if($code == 'MDA') return '🇲🇩'
    if($code == 'RI') return '🇺🇸'
    if($code == 'ROU') return '🇷🇴'
    if($code == 'RUS') return '🇷🇺'
    if($code == 'RWA') return '🇷🇼'
    if($code == 'KNA') return '🇰🇳'
    if($code == 'LCA') return '🇱🇨'
    if($code == 'VCT') return '🇻🇨'
    if($code == 'WSM') return '🇼🇸'
    if($code == 'SMR') return '🇸🇲'
    if($code == 'STP') return '🇸🇹'
    if($code == 'SK') return '🇨🇦'
    if($code == 'SAU') return '🇸🇦'
    if($code == 'SEN') return '🇸🇳'
    if($code == 'SRB') return '🇷🇸'
    if($code == 'SYC') return '🇸🇨'
    if($code == 'SLE') return '🇸🇱'
    if($code == 'SGP') return '🇸🇬'
    if($code == 'SXM') return '🇸🇽'
    if($code == 'SVK') return '🇸🇰'
    if($code == 'SVN') return '🇸🇮'
    if($code == 'SLB') return '🇸🇧'
    if($code == 'SOM') return '🇸🇴'
    if($code == 'ZAF') return '🇿🇦'
    if($code == 'SC') return '🇺🇸'
    if($code == 'SD') return '🇺🇸'
    if($code == 'SSD') return '🇸🇸'
    if($code == 'ESP') return '🇪🇸'
    if($code == 'LKA') return '🇱🇰'
    if($code == 'DV') return '🇺🇸'
    if($code == 'SDN') return '🇸🇩'
    if($code == 'SUR') return '🇸🇷'
    if($code == 'SWZ') return '🇸🇿'
    if($code == 'SWE') return '🇸🇪'
    if($code == 'CHE') return '🇨🇭'
    if($code == 'SYR') return '🇸🇾'
    if($code == 'TWN') return '🇹🇼'
    if($code == 'TJK') return '🇹🇯'
    if($code == 'TX') return '🇺🇸'
    if($code == 'THA') return '🇹🇭'
    if($code == 'MKD') return '🇷🇸'
    if($code == 'TGO') return '🇹🇬'
    if($code == 'TON') return '🇹🇴'
    if($code == 'TTO') return '🇹🇹'
    if($code == 'TUN') return '🇹🇳'
    if($code == 'TUR') return '🇹🇷'
    if($code == 'TKM') return '🇹🇲'
    if($code == 'TCA') return '🇹🇨'
    if($code == 'TUV') return '🇹🇻'
    if($code == 'UGA') return '🇺🇬'
    if($code == 'UKR') return '🇺🇦'
    if($code == 'ARE') return '🇦🇪'
    if($code == 'GBR') return '🇬🇧'
    if($code == 'UNK') return '🇽🇰'
    if($code == 'UNO') return '🇺🇳'
    if($code == 'TZA') return '🇹🇿'
    if($code == 'USA') return '🇺🇸'
    if($code == 'URY') return '🇺🇾'
    if($code == 'UT') return '🇺🇸'
    if($code == 'UTO') return '🇳🇨'
    if($code == 'UZB') return '🇺🇿'
    if($code == 'VUT') return '🇻🇺'
    if($code == 'VEN') return '🇻🇪'
    if($code == 'VT') return '🇺🇸'
    if($code == 'VNM') return '🇻🇳'
    if($code == 'WA') return '🇺🇸'
    if($code == 'WV') return '🇺🇸'
    if($code == 'WI') return '🇺🇸'
    if($code == 'WY') return '🇺🇸'
    if($code == 'YEM') return '🇾🇪'
    if($code == 'YUG') return '🇲🇪'
    if($code == 'ZMB') return '🇿🇲'
    if($code == 'ZWE') return '🇿🇼'
    return '';
}