import React, { Component } from 'react'
// import { ActiveTag, FailedTag, SuccessTag, imgUrl, InactiveTag } from '../../../utils';

export default class PreviewRadarResultLayout extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="row mt-3 p-4">
            {this?.props?.basicData?.length > 0 && (
                <div className="col-md-6 mt-4">
                    <div className="card endpoint-response">
                        <div className="card-body">
                            <h6>Basic Phone Data Result</h6>
                            <hr />
                            <div className="row justify-content-between mt-2">
                                {this?.props?.basicData?.map((item, i) => (
                                    <span
                                        className={
                                            (i + 1) % 2 === 1
                                                ? 'col-md-6 py-1'
                                                : 'col-md-6 text-md-end'
                                        }
                                        key={i}>
                                        <>
                                            <small>
                                                {this?.props?.getKeyLabel(item)?.replace(/_/g, ' ')}
                                            </small>
                                            <p>{this?.props?.props?.data?.basic[item] || 'Not Available'}</p>
                                        </>
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {this?.props?.whatsAppData?.length > 0 && (
                <div className="col-md-6 mt-4">
                    <div className="card endpoint-response">
                        <div className="card-body">
                            <h6>Whatsapp Phone Data Result</h6>
                            <hr />
                            {this?.props?.props?.data?.whatsapp?.profile_photo && (
                                <div className="text-center my-4">
                                    <img
                                        className="response-img"
                                        src={this?.props?.props?.data?.whatsapp?.profile_photo}
                                        alt=""
                                    />
                                    <br />
                                </div>
                            )}
                            <div className="row justify-content-between mt-2">
                                {this?.props?.whatsAppData?.map((item, i) => {
                                    if (item === 'profile_photo') {
                                        return <div></div>
                                    } else
                                        return (
                                            <span
                                                className={
                                                    (i + 1) % 2 === 1
                                                        ? 'col-md-6 py-1'
                                                        : 'col-md-6 text-md-end'
                                                }
                                                key={i}>
                                                <>
                                                    <small>
                                                        {this?.props?.getKeyLabel(item)?.replace(/_/g, ' ')}
                                                    </small>
                                                    <p>
                                                        {this?.props?.props?.data?.whatsapp[item] ||
                                                            'Not Available'}
                                                    </p>
                                                </>
                                            </span>
                                        )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {this?.props?.advanceData?.length > 0 && (
                <div className="col-md-6 mt-4">
                    <div className="card endpoint-response">
                        <div className="card-body">
                            <h6>Advanced Phone Data Result</h6>
                            <hr />
                            <div className="row justify-content-between mt-2">
                                {Object.keys(this?.props?.props?.data?.advance?.basic)?.map(
                                    (item, i) => (
                                        <span
                                            className={
                                                (i + 1) % 2 === 1
                                                    ? 'col-md-6 py-1'
                                                    : 'col-md-6 text-md-end'
                                            }
                                            key={i}>
                                            <>
                                                <small>
                                                    {this?.props?.getKeyLabel(item)?.replace(/_/g, ' ')}
                                                </small>
                                                <p>
                                                    {this?.props?.props?.data?.advance?.basic[item] ||
                                                        'Not Available'}
                                                </p>
                                            </>
                                        </span>
                                    )
                                )}
                            </div>
                            <div className="row justify-content-between mt-2">
                                {Object.keys(this?.props?.props?.data?.advance?.social)?.map(
                                    (item, i) => (
                                        <span
                                            className={
                                                (i + 1) % 2 === 1
                                                    ? 'col-md-6 py-1'
                                                    : 'col-md-6 text-md-end'
                                            }
                                            key={i}>
                                            <>
                                                <small>
                                                    {this?.props?.getKeyLabel(item)?.replace(/_/g, ' ')}
                                                </small>
                                                <p>
                                                    {this?.props?.props?.data?.advance?.social[item] ||
                                                        'Not Available'}
                                                </p>
                                            </>
                                        </span>
                                    )
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
        )
    }
}
