import React from 'react'
import WebhookComp from '../../components/sdkLibraries/webhook'

export default function SDKLibraryPage() {
  return (
    <div>
        <WebhookComp/>
    </div>
  )
}
