import React, { Component } from 'react'
import { ActiveTag, FailedTag, SuccessTag, imgUrl, InactiveTag } from '../../utils';

export default class PreviewResultLayout extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className='row justify-content-between mt-3 p-4'>
              <div className="text-center">
                <h5>{this?.props?.props?.data?.endpoint?.name}</h5>
                <small className="">Verification Status: </small>
                {(this?.props?.responseData?.response_code === "00" || this?.props?.responseData?.response_code === "01") ?
                    <>
                        <SuccessTag /><br />
                    </>
                    :
                    <>
                        <FailedTag /><br />
                    </>
                }
            </div>
              {!this?.props?.getResponseImg() || this?.props?.getResponseImg()?.length < 10 ? "":
                    <div className="text-center my-4">
                        {this?.props?.responseData?.response_code === "00" ?
                            <img className="response-img" alt="dashboard-user"
                            style={{
                              width: '120px',
    height: '120px',
    borderRadius: '50%'
                            }}
                                src={this?.props?.getResponseImg()?.includes("https://") ? this?.props?.getResponseImg() : this?.props?.imgUrl(this?.props?.getResponseImg())}
                            // src={imgUrl(getResponseImg())}
                            />
                            :
                            ""}
                            </div>
                            }
            {!this?.props?.openDirectors && this?.props?.idDataKeys?.map((key, index) => {
                if (typeof this?.props?.idData[key] !== "object") {
                    return (
                      key !== 'file_base64' && (
                        <span className={(index + 1) % 2 === 1 ? "col-md-6 py-1" : "col-md-6 text-md-end"} key={index} style={{ borderBottom: "1px solid #ddd", paddingBottom: "0px", marginBottom: "0px" }}>

                            {((key !== "photo") && (key !== "Photo") && (key !== "image") && (key !== "base64Image") && (key !== "DriverImage")&& (key !== "file_base64") && (key !== "signature") && (key !== "directors") && (key !== "expirationDate") && (key !== "service_provider")) &&
                                <>
                                    <small>{this?.props?.getKeyLabel(key)?.replace(/_/g, " ")}</small>
                                    <p>{(this?.props?.idData[key] || 'Not Available')}</p>
                                    {/* {typeof idData[key] === "object" ? "" : */}
                                    {/* <p>{idData[key]?.replace("null", '""') || 'Not Available'}</p> */}
                                    {/* } */}
                                </>
                            }


                            {key === "signature" &&
                                <>
                                    <small>{this?.props?.getKeyLabel(key)}</small> <br />
                                    {key === "signature" ?
                                        <img style={{ width: "50px" }} src={this?.props?.idData[key]} alt='' />
                                        :
                                        <p>{'Not Available'}</p>
                                    }
                                </>
                            }

                            {key === "expirationDate" &&
                                <>
                                    <small>{this?.props?.getKeyLabel(key)}</small> <br />
                                    <p>
                                        <span className={`${this?.props?.idData["documentStatus"] === "EXPIRED" ? "text-danger" : ""}`}>
                                            {(this?.props?.idData[key] || 'Not Available')}
                                        </span>
                                    </p>
                                </>
                            }

                            {(key === "directors" || key === "service_provider") && <> </>}
                        </span>
                      )
                    )
                }
                else {
                    return (
                      key !== 'directors' ? (
                        <span className={(key === "0" || key === "1" || key === "2" || key === "3") && (key !== "directors" || key !== "signature" || key !== "PerformanceSummary" || key !== "expirationDate" || key !== "service_provider") ? "col-md-12 py-1" : ((index + 1) % 2 === 1 ? "col-md-6 py-1" : "col-md-6 text-md-end")}
                            key={index} style={{ borderBottom: `${(key === "0" || key === "1" || key === "2" || key === "3") && (key !== "directors" || key !== "signature" || key !== "PerformanceSummary" || key !== "expirationDate" || key !== "service_provider") ? "" : "1px solid #ddd"}`, paddingBottom: "0px", marginBottom: "0px" }}
                        >
                            <>
                                {(this?.props?.idData[key]?.length < 1) ?
                                    <>
                                        <small>{this?.props?.getKeyLabel(key).replace(/_/g, " ")}</small>
                                        <p>Not Available</p>
                                    </>
                                    :
                                    <>
                                        {(key === "0" || key === "1" || key === "2" || key === "3") && (key !== "directors" || key !== "signature" || key !== "PerformanceSummary" || key !== "expirationDate" || key !== "service_provider") ?
                                            <div className='row'>
                                                {Object?.keys(this?.props?.idData[key])?.map((val, k) => {
                                                    return (
                                                        <span className={(k + 1) % 2 === 1 ? "col-md-6 py-1" : "col-md-6 text-md-end"} key={k} style={{ borderBottom: "1px solid #ddd", paddingBottom: "0px", marginBottom: "0px" }}>
                                                            <small>{this?.props?.getKeyLabel(val).replace(/_/g, " ")}</small>
                                                            <p>{`${this?.props?.idData[key][val]?.replace("null", '""')}` || "Not Available"}</p>
                                                        </span>
                                                    )
                                                    // }
                                                })}
                                            </div>
                                            :
                                            <>
                                                <div className={`d-flex ${(index + 1) % 2 === 1 ? "justify-content-md-start" : "justify-content-md-end pt-2"}`}>
                                                    <small>{this?.props?.getKeyLabel(key).replace(/_/g, " ")}</small>
                                                    {(this?.props?.openDataChild && this?.props?.dataChild === this?.props?.getKeyLabel(key)) ?
                                                        <i className="ri-arrow-up-s-line ri-lg ms-3"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                              this?.props?.setOpenDataChild(false)
                                                                this?.props?.setDataChild("")
                                                            }}
                                                        />
                                                        :
                                                        <i className="ri-arrow-down-s-line ri-lg ms-3"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                              this?.props?.setOpenDataChild(true)
                                                                this?.props?.setDataChild(this?.props?.getKeyLabel(key))
                                                            }}
                                                        />
                                                    }
                                                </div>

                                                {(this?.props?.openDataChild && this?.props?.dataChild === this?.props?.getKeyLabel(key)) &&
                                                    <div>
                                                        {this?.props?.idData[key] && Object?.keys(this?.props?.idData[key])?.map(val => {
                                                            if ((this?.props?.idData[key][val]?.length === undefined) && (typeof this?.props?.idData[key][val] === "object")) {
                                                              this?.props?.idData[key][val] && Object?.keys(this?.props?.idData[key][val])?.map(newVal => (
                                                                    <>
                                                                        <small>{this?.props?.getKeyLabel(newVal).replace(/_/g, " ")}</small>
                                                                        <p>{`${this?.props?.idData[key][val][newVal]?.replace("null", '""')}` || "Not Available"}</p>
                                                                    </>
                                                                ))
                                                            }
                                                            else {
                                                                return (
                                                                    <>
                                                                        <small>{this?.props?.getKeyLabel(val).replace(/_/g, " ")}</small>
                                                                        <p>{`${this?.props?.idData[key][val]?.replace("null", '""')}` || "Not Available"}</p>

                                                                    </>
                                                                )
                                                            }
                                                        })}
                                                    </div>
                                                }
                                            </>
                                        }
                                    </>
                                }

                            </>
                        </span>
                      ) : (
                        <></>
                      )
                    )
                    // }
                }
            })}
            {(this?.props?.idDataKeys?.filter(dir => (dir === "directors")).length > 0) &&
                this.props?.idData['directors']?.map((value, index) => (
                    <div key={index}>
                        <div className='row justify-content-between'>
                            <span className="col-md-6">
                                <small>Fullname</small>
                                <p>{value?.name || `${value?.firstname} ${value?.otherName} ${value?.surname}`}</p>
                            </span>
                            <span className="col-md-6 text-md-end">
                                <small>Gender</small>
                                <p>{value?.gender || "Not Available"}</p>
                            </span>
                        </div><hr />
                        <div className='row justify-content-between'>
                            <span className="col-md-6">
                                <small>Date of Birth</small>
                                {/* <p>{moment(value?.dateOfBirth).format("MMMM Do YYYY, h:mm") || "Not Available"}</p> */}
                            </span>
                            <span className="col-md-6 text-md-end">
                                <small>Email</small>
                                <p>{value?.email || "Not Available"}</p>
                            </span>
                        </div><hr />
                        <div className='row justify-content-between'>
                            <span className="col-md-6">
                                <small>Phone Number</small>
                                <p>{value?.phoneNumber || "Not Available"}</p>
                            </span>
                            <span className="col-md-6 text-md-end">
                                <small>Address</small>
                                <p>{value?.address || "Not Available"}</p>
                            </span>
                        </div><hr />
                        <div className='row justify-content-between'>
                            <span className="col-md-6">
                                <small>City</small>
                                <p>{value?.city || "Not Available"}</p>
                            </span>
                            <span className="col-md-6 text-md-end">
                                <small>LGA</small>
                                <p>{value?.lga || "Not Available"}</p>
                            </span>
                        </div><hr />
                        <div className='row justify-content-between'>
                            <span className="col-md-6">
                                <small>State</small>
                                <p>{value?.state || "Not Available"}</p>
                            </span>
                            <span className="col-md-6 text-md-end">
                                <small>Postcode</small>
                                <p>{value?.postcode || "Not Available"}</p>
                            </span>
                        </div><hr />
                        <div className='row justify-content-between'>
                            <span className="col-md-6">
                                <small>Country</small>
                                <p>{value?.countryFk?.name || "Not Available"}</p>
                            </span>
                            <span className="col-md-6 text-md-end">
                                <small>Nationality</small>
                                <p>{value?.nationality || "Not Available"}</p>
                            </span>
                        </div><hr />
                        <div className='row justify-content-between'>
                            <span className="col-md-6">
                                <small>Identity Number</small>
                                <p>{value?.identityNumber || "Not Available"}</p>
                            </span>
                            <span className="col-md-6 text-md-end">
                                <small>Accreditation Number</small>
                                <p>{value?.accreditationnumber || "Not Available"}</p>
                            </span>
                        </div><hr />
                        <div className='row justify-content-between'>
                            <span className="col-md-6">
                                <small>Occupation</small>
                                <p>{value?.occupation || "Not Available"}</p>
                            </span>
                            <span className="col-md-6 text-md-end">
                                <small>Date of Appointment</small>
                                {/* <p>{moment(value?.dateOfAppointment).format("MMMM Do YYYY, h:mm") || "Not Available"}</p> */}
                            </span>
                        </div><hr />
                        <div className='row justify-content-between'>
                            <span className="col-md-6">
                                <small>Shares Alloted</small>
                                <p>{value?.numSharesAlloted || value?.shares || "Not Available"}</p>
                            </span>
                            <span className="col-md-6 text-md-end">
                                <small>Type of Shares</small>
                                <p>{value?.typeOfShares || "Not Available"}</p>
                            </span>
                        </div><hr />
                        <div className='row justify-content-between'>
                            <span className="col-md-6">
                                <small>Affiliate</small>
                                <p>{value?.affiliateTypeFk?.name || "Not Available"}</p>
                            </span>
                            <span className="col-md-6 text-md-end">
                                <small>Affiliate Description</small>
                                <p>{value?.affiliateTypeFk?.description || "Not Available"}</p>
                            </span>
                        </div><hr />
                        {value?.status === "ACTIVE" && (
                        <>
                          <div className='row justify-content-between'>
                            <span className="col-md-6">
                                <small>Status</small>
                                <p>{value?.status === "ACTIVE" ? <ActiveTag /> : <InactiveTag />}</p>
                            </span>
                        </div><hr />
                        </>
                          )}
                    </div>

                ))                        
            }
        </div>
        )
    }
}
