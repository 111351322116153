import React, { Component } from 'react'
// import { ActiveTag, FailedTag, SuccessTag, imgUrl, InactiveTag } from '../../../utils';

export default class PreviewRadarResultLayout extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="row mt-3 p-4">
            <div className="row">
                {this?.props?.domainData && this?.props?.domainData?.length > 0 && (
                    <div className="col-md-6 mt-4">
                        <div className="card endpoint-response">
                            <div className="card-body">
                                <h6>Domain Data Result</h6>
                                <hr />
                                <div className="row justify-content-between mt-2">
                                    {this?.props?.domainData && this?.props?.domainData?.map((item, i) => (
                                        <span
                                            className={
                                                (i + 1) % 2 === 1
                                                    ? 'col-md-6 py-1'
                                                    : 'col-md-6 text-md-end'
                                            }
                                            key={i}>
                                            <>
                                                <small>
                                                    {this?.props?.getKeyLabel(item)?.replace(/_/g, ' ')}
                                                </small>
                                                <p>
                                                    {this?.props?.props?.data?.domain_info[item] ||
                                                        'Not Available'}
                                                </p>
                                            </>
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this?.props?.basicData && this?.props?.basicData?.length > 0 && (
                    <div className="col-md-6 mt-4">
                        <div className="card endpoint-response">
                            <div className="card-body">
                                <h6>Basic Email Data Result</h6>
                                <hr />
                                <div className="row justify-content-between mt-2">
                                    {this?.props?.basicData?.map((item, i) => (
                                        <span
                                            className={
                                                (i + 1) % 2 === 1
                                                    ? 'col-md-6 py-1'
                                                    : 'col-md-6 text-md-end'
                                            }
                                            key={i}>
                                            <>
                                                <small>
                                                    {this?.props?.getKeyLabel(item)?.replace(/_/g, ' ')}
                                                </small>
                                                <p>{this?.props?.props?.data?.basic[item] || 'Not Available'}</p>
                                            </>
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {this?.props?.emailData && this?.props?.emailData?.length > 0 && (
                    <div className="col-md-6 mt-4">
                        <div className="card endpoint-response">
                            <div className="card-body">
                                <h6>Advanced Email Data Result</h6>
                                <hr />
                                <div className="row justify-content-between mt-2">
                                    {this?.props?.emailData?.map((item, i) => (
                                        <span
                                            className={
                                                (i + 1) % 2 === 1
                                                    ? 'col-md-6 py-1'
                                                    : 'col-md-6 text-md-end'
                                            }
                                            key={i}>
                                            <>
                                                <small>
                                                    {this?.props?.getKeyLabel(item)?.replace(/_/g, ' ')}
                                                </small>
                                                {/* <p>{props?.data?.email_info[item] || 'Not Available'}</p> */}
                                                <p>
                                                    {typeof this?.props?.props?.data?.email_info[item] ===
                                                    'object'
                                                        ? ''
                                                        : this?.props?.props?.data?.email_info[item][0]
                                                        ? 'Registered'
                                                        : 'Not Registered'}
                                                </p>
                                                {
                                                    // props?.data?.email_info[item]?.map((val:any, ind:number) =>(
                                                    //     <p>{JSON.stringify(val) || 'Not Available'}</p>
                                                    // ))
                                                }
                                            </>
                                        </span>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
        )
    }
}
