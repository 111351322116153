export const referralSectors = [
    { sector: "Email Marketing" },
    { sector: "Events"},
    { sector: "Friend/Family" },
    { sector: "Google Ads" },
    { sector: "Organic Search" },
    { sector: "Partners/Perk programs" },
    { sector: "Referral" },
    { sector: "Social Media" },
    { sector: "Website" },
    { sector: "Webinars" },
]