import React, { Component } from 'react'
// import { ActiveTag, FailedTag, SuccessTag, imgUrl, InactiveTag } from '../../../utils';

export default class PreviewRadarResultLayout extends Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <div className="row mt-3 p-4">
            <div className="row">
                {this?.props?.sanctionData && this?.props?.sanctionData?.length > 0 && (
                    <div className="col-md-6 mt-4">
                        <div className="card endpoint-response">
                            <div className="card-body">
                                <h6>Sanction Result</h6>
                                <hr />
                                <div className="row justify-content-between mt-2">
                                    {this?.props?.sanctionData?.map((item, i) => (
                                        <>
                                             <span
                                            className={
                                                (i + 1) % 2 === 1
                                                    ? 'col-md-6 py-1'
                                                    : 'col-md-6 text-md-end'
                                            }
                                            key={i}>
                                            <>
                                                <small>Name</small>
                                                <p>{item.name || 'Not Available'}</p>
                                                
                                            </>
                                        </span>
                                            <span className="col-md-12">
                                            <small>Summary</small>
                                            <p>{item.summary || 'Not Available'}</p>
                                        </span>
                                        </>
                                       
                                    ))}
                                </div>
                                {/* <div className="row justify-content-between mt-2">
                                    
                                </div> */}
                            </div>
                        </div>
                    </div>
                )}

                {this?.props?.pepData && this?.props?.pepData?.length > 0 && (
                    <div className="col-md-6 mt-4">
                        <div className="card endpoint-response">
                            <div className="card-body">
                                <h6>PEP Data Result</h6>
                                <hr />

                                        <>
                                            {this?.props?.pepData.map((item, i) => (
                                                <div key={i}>
                                                    <div className="row justify-content-between mt-2">
                                                        <span className="col-md-6">
                                                            <small>Caption</small>
                                                            <p>{item.caption || 'Not Available'}</p>
                                                        </span>
                                                        <span className="col-md-6 text-md-end">
                                                            <small>Schema</small>
                                                            <p>{item.schema || 'Not Available'}</p>
                                                        </span>
                                                    </div>

                                                    {item?.properties && (
                                                        <div className="row justify-content-between mt-2">
                                                            {Object.keys(item?.properties)?.map(
                                                                (val, k) => (
                                                                    <span
                                                                        className={
                                                                            (k + 1) % 2 === 1
                                                                                ? 'col-md-6 py-1'
                                                                                : 'col-md-6 text-md-end'
                                                                        }
                                                                        key={k}>
                                                                        {val !== 'wikidataId' && (
                                                                            <>
                                                                                <small>
                                                                                    {this?.props?.getKeyLabel(
                                                                                        val
                                                                                    )?.replace(
                                                                                        /_/g,
                                                                                        ' '
                                                                                    )}
                                                                                </small>
                                                                                {item?.properties[
                                                                                    val
                                                                                ].length > 0 && (
                                                                                    <>
                                                                                        {item?.properties[
                                                                                            val
                                                                                        ]?.map(
                                                                                            (
                                                                                                pVal,
                                                                                                pIndex
                                                                                            ) => (
                                                                                                <p
                                                                                                    key={
                                                                                                        pIndex
                                                                                                    }>
                                                                                                    {pVal ||
                                                                                                        'Not Available'}
                                                                                                </p>
                                                                                            )
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </span>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            ))}
                                        </>
                            </div>
                        </div>
                    </div>
                )}

                {(this?.props?.ofacData && this?.props?.ofacData?.length > 0 && typeof this?.props?.ofacData !== 'string') && (
                    <div className="col-md-6 mt-4">
                        <div className="card endpoint-response">
                            <div className="card-body">
                                <h6>OFAC Data Result</h6>
                                <hr />
                                        <>
                                            {this?.props?.ofacData && this?.props?.ofacData?.map((item, index) => (
                                                <div key={index}>
                                                    <div className="row justify-content-between mt-2">
                                                        <span className="col-md-6">
                                                            <small>Name</small>
                                                            <p>
                                                                {item?.entity_name ||
                                                                    'Not Available'}
                                                            </p>
                                                        </span>
                                                        <span className="col-md-6 text-md-end">
                                                            <small>Program</small>
                                                            <p>{item?.program || 'Not Available'}</p>
                                                        </span>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                            </div>
                        </div>
                    </div>
                )}
                </div>
        </div>
        )
    }
}
